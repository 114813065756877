
import DataGrid from "@/core-ui/data-grid/components/DataGrid.vue";
import {
    useDataGridModel,
    toDisplayColumns,
    ActionOn,
    useDeleteModalAction,
    DataGridAction,
} from "@/core-ui/data-grid/compositions";
import ModelDetailsPanel from "../components/jobs/JobDetailsPanel.vue";
import Page from "@/core-ui/Page.vue";
import { createModelProps, ModalType as Item, meta } from "@/models/workspace.model";
import { useClusterUUIDKey } from "../compositions/ClusterUUIDKey";
import { computed, defineComponent, ref, reactive, onMounted } from "vue";
import { useRouter } from "vue-router";
import clusterJobService from "@/cluster-ui/services/job.service";
import DeleteModalAction from "@/core-ui/data-grid/components/DeleteModal/DeleteModalAction.vue";
import { clusterApi } from "@/cluster-ui/services/apiClient";
import authStore from "@/stores/authStore";
import { Job } from "@/types/job";
import { LabelType } from "@/core-ui/StatusLabel.vue";
import projectsService from "@/services/projects-service";
import _ from "lodash";

export default defineComponent({
    components: {
        Page,
        DataGrid,
        ModelDetailsPanel,
        DeleteModalAction,
    },
    setup() {
        const HttpStatusCompound = 207;
        const props = useClusterUUIDKey({ key: "nodes" });
        const model = useDataGridModel(createModelProps(props, "page"));
        const router = useRouter();
        const state = reactive({
            projectsList: null,
        });
        const isAdmin = authStore.userInfo.roles.includes("admin");

        async function fetchProjectsList() {
            state.projectsList = await projectsService.getDesiredQueues();
        }

        fetchProjectsList();

        const actionDisabledMsgs = {
            connectivityProblem: (activity: string) => `Connectivity to cluster is required in order to ${activity}`,
            versionProblem: (activity: string) =>
                `To ${activity}, your cluster needs to have the latest version installed.`,
            noProjectProblem: () =>
                isAdmin
                    ? "To submit a new workspace, First create at least one project"
                    : "To create a workspace, you need to be assigned to a project. Contact your administrator to be assigned. ",
        };

        function handleJobAction(d: any, actionName: string) {
            //
            //   handle compound error. This is temporary placeholder, until multiple-job request
            //   is supported. For now, if we get compound and the status of the first (and only)
            //   job indicate action failed, raise exception so an error message will be displayed.
            //
            if (d.status == HttpStatusCompound && d.data.data?.length) {
                if (d.data.data.some((job: any) => !job.ok)) {
                    throw `${actionName} failed`;
                }
            } else {
                setTimeout(() => model.refresh(), 4000);
            }
        }
        const mapToClusterJobId = ({ project, jobName }: Job) => ({ project, name: jobName } as any);
        const deleteModal = useDeleteModalAction<Item>({
            modelMeta: meta,
            delete: (jobs: Item[]) =>
                clusterJobService.delete(jobs.map(mapToClusterJobId)).then((d) => {
                    handleJobAction(d, "Deletion");
                    return d;
                }) as any,
        });

        const isResearcherAndNotProduction = computed(() => authStore.isResearcher);
        const s = ref(false);
        (window as any).ss = s;

        const TrainingJob = "Train"; // the db and cluster jobs are differance ("Train", "Training")
        return {
            state,
            model,
            deleteModal,
            get displayColumns() {
                return toDisplayColumns(null, model.columns);
            },
            actions: [
                {
                    on: ActionOn.Item,
                    key: "logs",
                    icon: "raicon-log",
                    label: `Logs`,
                    aid: "log-workspace",
                    permitted: true,
                    disabled: () =>
                        (!clusterApi.connected && actionDisabledMsgs.connectivityProblem("show workspace's logs")),
                    filter: (j: Job) => j.existsInCluster && isResearcherAndNotProduction.value,
                    func: (job: any) =>  router.push(`/jobs/${job.project}/${job.jobName}/${job.podGroupId}/log`) as any,
                },
                {
                    on: ActionOn.Item,
                    key: "delete",
                    icon: "raicon-remove",
                    label: `Delete Workspace`,
                    aid: "delete-workspace",
                    filter: (j: Item) => j.existsInCluster && isResearcherAndNotProduction.value,
                    disabled: () =>
                        (!clusterApi.connected && actionDisabledMsgs.connectivityProblem("delete a workspace")),
                    permitted: true,
                    func: deleteModal.handle,
                },
                {
                    on: ActionOn.Item,
                    key: "connect",
                    icon: "raicon-play",
                    label: `Connect`,
                    aid: "connect-workspace",
                    permitted: true,
                    disabled: () =>
                        (!clusterApi.connected && actionDisabledMsgs.connectivityProblem("connect workspace")),
                    filter: (job: Job) =>
                        job.existsInCluster &&
                        isResearcherAndNotProduction.value &&
                        !!job.jobUrl &&
                        job.status === "Running",
                    func: (job: Job) => window.open(job.jobUrl, "_blank"),
                },
                {
                    on: ActionOn.Item,
                    key: "suspend",
                    icon: "raicon-pause",
                    label: `Suspend`,
                    aid: "suspend-workspace",
                    permitted: true,
                    disabled: () =>
                        (!clusterApi.connected && actionDisabledMsgs.connectivityProblem("suspend workspace")),
                    filter: (job: Job) =>
                        job.existsInCluster &&
                        isResearcherAndNotProduction.value &&
                        job.jobType === TrainingJob &&
                        job.status != LabelType.SUSPENDED,
                    func: (job: Job) =>
                        clusterJobService.suspend([mapToClusterJobId(job)]).then((d) => {
                            handleJobAction(d, "Suspend");
                            return {
                                type: "success",
                                msg: `Workspace ${job.name || job.job_name} suspended successfully`,
                            };
                        }),
                },
                {
                    on: ActionOn.Item,
                    key: "resume",
                    icon: "raicon-play",
                    label: `Resume`,
                    permitted: true,
                    aid: "resume-workspace",
                    disabled: () =>
                        (!clusterApi.connected && actionDisabledMsgs.connectivityProblem("resume workspace")),
                    filter: (job: Job) =>
                        job.existsInCluster &&
                        isResearcherAndNotProduction.value &&
                        job.jobType === TrainingJob &&
                        job.status == LabelType.SUSPENDED,
                    func: (job: any) =>
                        clusterJobService.resume([mapToClusterJobId(job)]).then((d) => {
                            handleJobAction(d, "Resume");
                            return {
                                type: "success",
                                msg: `Workspace ${job.name || job.jobName} resumed successfully`,
                            };
                        }),
                },
                {
                    on: ActionOn.None,
                    key: "add",
                    label: "+ New Workspace",
                    aid: "add-workspace",
                    disabled: () =>
                        (!clusterApi.connected && actionDisabledMsgs.connectivityProblem("submit a new workspace")) ||
                        (_.isEmpty(state.projectsList) && actionDisabledMsgs.noProjectProblem()),
                    filter: () => authStore.isResearcher,
                    permitted: true,
                    func: () => router.push("/workspaces/new") as any,
                },
            ] as DataGridAction[],
        };
    },
});
