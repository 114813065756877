import {AddDefaultSort, createModelMeta, DataGridModelProps, Filter} from "@/core-ui/data-grid/compositions";
import { DataGridColumn } from "@/core-ui/types/column";
import { dateFormat, durationFormat, numeric } from "@/core-ui/helpers/data-formats";
import { ClusterUUIDKey } from "@/compositions/ClusterUUIDKey";
import jobsService from "@/services/jobs-service";
import { limitDigitsNumber } from "@/core-ui/helpers/data-formats";
import MemoryLabel from "@/core-ui/MemoryLabel.vue";
import { toFilterAPI } from "@/core-ui/data-grid/utils";
import { computed, ref } from "vue";
import StatusLabelWithTooltip from "@/components/jobs/StatusLabelWithTooltip.vue";
import { createCompLinkForProject, useProjectLazyCache } from "./projects.model";
import { createCompLinkForNode, useNodeLazyCache } from "./nodes.model";
import { Job } from "@/types/job";
import authStore from "@/stores/authStore";
const stringFormat = (v: string) => v || "-";

export const columns = ({ projectCache, nodeCache }: { projectCache: any; nodeCache: any }): DataGridColumn[] => [
    {
        key: "job",
        label: "Workspaces Name",
        dataKey: "jobName",
        sortable: true,
        searchable: true,
        display: {
            table: {
                width: 280,
            },
        },
    },
    {
        key: "status",
        label: "Status",
        dataKey: "training_lib",
        reverseKey: "status",
        sortable: true,
        searchable: true,
        display: {
            component: StatusLabelWithTooltip,
            table: {
                width: 150,
            },
        },
    },
    {
        key: "image",
        label: "Image",
        dataKey: "imageName",
        sortable: true,
        searchable: true,
        display: {
            table: {
                width: 200,
                // it is hidden until we update most of our tenants
                hidden: true,
            },
        },
    },
    {
        key: "job-id",
        primary: true,
        label: "Job ID",
        dataKey: "podGroupId",
        searchable: true,
        dataTransform: stringFormat,
        display: {
            table: {
                width: 200,
                hidden: true,
            },
        },
    },
    {
        key: "user",
        label: "User",
        dataKey: "user",
        sortable: true,
        searchable: true,
        display: {
            table: {
                width: 120,
            },
        },
    },
    {
        key: "project",
        label: "Project",
        dataKey: "project",
        searchable: true,
        sortable: true,
        display: {
            ...createCompLinkForProject(projectCache),
            table: {
                width: 180,
            },
        },
    },
    {
        key: "node",
        label: "Node(s)",
        dataKey: "nodeId",
        searchable: true,
        display: {
            ...(authStore.canViewClusterManagementResources ? createCompLinkForNode(nodeCache) : {}),
            table: {
                width: 200,
                hidden: true,
            },
        },
    },
    {
        key: "nodePool",
        label: "Node Pool",
        dataKey: "nodePool",
        searchable: true,
        display: {
            table: {
                width: 200,
                hidden: true,
            },
        },
    },
    {
        key: "runtime",
        label: "Total Run Time",
        dataKey: "totalRuntime",
        sortable: true,
        dataTransform: durationFormat,
        display: {
            table: {
                width: 175,
            },
        },
    },
    {
        key: "wait-time",
        label: "Total Wait Time",
        dataKey: "totalWaitTime",
        dataTransform: durationFormat,
        sortable: true,
        display: {
            table: {
                width: 175,
                hidden: true,
            },
        },
    },
    {
        key: "creation",
        label: "Creation Time",
        dataKey: "creationTime",
        sortable: true,
        dataTransform: (str) => dateFormat(Number(str)),
        display: {
            table: {
                width: 160,
            },
        },
    },
    {
        key: "gpus-utilization",
        label: "GPU Utilization",
        dataKey: "gpusUtilization",
        dataTransform: limitDigitsNumber(1, "%"),
        display: {
            table: {
                width: 150,
            },
        },
    },
    {
        key: "requested-gpus",
        label: "Requested GPUs",
        dataKey: "requestedGPUsPerPodGroup",
        sortable: true,
        searchable: true,
        display: {
            table: {
                width: 200,
                hidden: true,
            },
        },
    },
    {
        key: "currentAllocatedGPUs",
        label: "Allocated GPUs",
        dataKey: "currentAllocatedGPUs",
        sortable: true,
        searchable: true,
        display: {
            table: {
                width: 200,
                hidden: true,
            },
        },
    },
    {
        key: "used-gpu-memory",
        label: "Used GPU Memory",
        dataKey: "usedGpuMemory",
        dataTransform: numeric,
        display: {
            component: MemoryLabel,
            table: {
                width: 170,
                hidden: true,
            },
        },
    },
    {
        key: "currentAllocatedGPUsMemory",
        label: "Allocated GPU Memory",
        dataKey: "currentAllocatedGPUsMemory",
        sortable: true,
        searchable: true,
        display: {
            table: {
                width: 200,
                hidden: true,
            },
        },
    },
    {
        key: "used-cpus",
        label: "Used CPU",
        dataKey: "usedCPUs",
        dataTransform: numeric,
        display: {
            table: {
                width: 150,
            },
        },
    },
    {
        key: "used-memory",
        label: "Used CPU Memory",
        dataKey: "usedMemory",
        dataTransform: numeric,
        display: {
            component: MemoryLabel,
            table: {
                width: 200,
            },
        },
    },
    {
        key: "jobUrl",
        label: "Service URL(s)",
        dataKey: "jobUrl",
        sortable: true,
        searchable: true,
        display: {
            table: {
                width: 160,
                hidden: false,
            },
        },
    },
    {
        key: "swap-cpu-memory",
        label: "Used Swap CPU Memory",
        dataKey: "swapCPUMemory",
        dataTransform: numeric,
        display: {
            component: MemoryLabel,
            table: {
                width: 200,
                hidden: true,
            },
        },
    },
    {
        key: "running",
        label: "Running Pods",
        dataKey: "running",
        sortable: true,
        searchable: true,
        display: {
            table: {
                width: 200,
                hidden: true,
            },
        },
    },
];

export type ModalType = Job;
const type = "work-space";
export const meta = createModelMeta<ModalType>({
    icon: (j) => {
        if (j.isJupyter) {
            return {
                type: "img",
                src: "/img/jupyter.svg",
                tooltip: "Jupyter Notebook",
            }
        } else if (j.isTensorboard) {
            return {
                type: "img",
                src: "/img/tensorboard.png",
                tooltip: "TensorBoard",
            }
        } else {
            return `raicon-${type}`
        }
    },
    type,
    display: (item: ModalType) => item.jobName,
});

export const createModelProps = (
    props: ClusterUUIDKey,
    variant: "page" | "onlyData",
    preFilter: { node?: string; status?: string } = {},
): DataGridModelProps<ModalType> => {
    const dynamicColumns = ref({});
    const projectCache = useProjectLazyCache();
    const nodeCache = useNodeLazyCache();

    const computedColumns = computed(() => {
        return AddDefaultSort(columns({
            projectCache,
            nodeCache,
        }), type)
    });
    return {
        get tableName() {
            return type;
        },
        get dataKey() {
            return props.dataKey;
        },
        meta,
        get columns() {
            return [...computedColumns.value, ...Object.values(dynamicColumns.value)] as DataGridColumn[];
        },
        syncServer: true,
        fetchInterval: 10000,
        fetch: (async (filter: Filter) => {
            const f = toFilterAPI(computedColumns.value, filter, preFilter);
            const data = await jobsService.getWorkspaces(props.clusterUUID, f);
            dynamicColumns.value = data.columns;
            data.data = jobsService.getListOfQueryResultValues(data.data);
            return data as any;
        }) as any,
        entryProps: {
            tableRow: (j) => (j.existsInCluster === false ? { class: "workspace-deleted" } : {}),
        },
        options:
            variant == "onlyData" &&
            ({
                noSelection: true,
                noSearch: true,
            } as any),
    };
};
