import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DeleteModalAction = _resolveComponent("DeleteModalAction")!
  const _component_ModelDetailsPanel = _resolveComponent("ModelDetailsPanel")!
  const _component_DataGrid = _resolveComponent("DataGrid")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, null, {
    default: _withCtx(() => [
      _createVNode(_component_DeleteModalAction, { state: _ctx.deleteModal }, null, 8, ["state"]),
      _createVNode(_component_DataGrid, {
        model: _ctx.model,
        actions: _ctx.actions,
        "sync-url": true
      }, {
        item: _withCtx((props) => [
          _createVNode(_component_ModelDetailsPanel, {
            job: props.item,
            columns: _ctx.displayColumns,
            onClose: props.clearSelection
          }, null, 8, ["job", "columns", "onClose"])
        ]),
        _: 1
      }, 8, ["model", "actions"])
    ]),
    _: 1
  }))
}